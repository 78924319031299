<template>
  <header>
    <PMenubar
      :model="menuItems"
      :pt="{ root: ['rounded-none'] }"
      :pt-options="{ mergeProps: true }"
    >
      <template #start>
        <Icon name="igloo:igloo-text" size="30" class="mx-4" />
      </template>
      <template #item="{ item, props }">
        <nuxt-link v-bind="props.action" :to="item.route" class="text-primary">
          {{ item.label }}
        </nuxt-link>
      </template>
      <template #end>
        <div class="flex items-center gap-2">
          <ui-i18n-language-switch />

          <PAvatar
            class="mr-2 cursor-pointer !bg-primary"
            aria-haspopup="true"
            aria-controls="account_overlay_menu"
            @click="toggle"
          >
            <Icon class="text-white" name="lucide:user" size="24" />
          </PAvatar>
          <PMenu
            id="account_overlay_menu"
            ref="menuRef"
            :model="userMenuItems"
            size="small"
            popup
          />
        </div>
      </template>
    </PMenubar>
  </header>
</template>

<script lang="ts" setup>
const router = useRouter()
const { t } = useI18n()

const store = useAuthStore()

const menuRef = useTemplateRef('menuRef')
const menuItems = computed(() => [
  {
    label: t('menu.documents'),
    route: '/documents'
  },
  {
    label: t('menu.contracts'),
    route: '/contracts'
  },
  {
    label: t('menu.templates'),
    route: '/templates'
  }
])
const userMenuItems = [
  {
    label: 'Logout',
    command: () => logout()
  }
]

const logout = () => {
  store.logout()
  router.push('/login')
}
function toggle(event: Event) {
  menuRef.value?.toggle(event)
}
</script>
